@import '../../../styles/utils';
@import '../../../styles/palette';
@import '../../../styles/typography';

.big {
  height: grid_units(7);
  padding-right: grid_units(2);
  padding-left: grid_units(2);
  font-size: 14px;
}

.small {
  width: auto;
  height: grid_units(5);
  padding-right: grid_units(2);
  padding-left: grid_units(2);
  font-size: 12px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: grid_units(5);
  height: grid_units(5);
  padding: 0;
  font-size: 14px;
  letter-spacing: 0;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms ease;
  border: none;
  border-radius: grid_units(0.5);
  color: $white;
  text-transform: uppercase;
  cursor: pointer;
  gap: 10px;

  &--center {
    justify-content: center;
  }

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.red {
  background-color: $red-500;

  @include screenIn(desktop) {
    &:hover {
      background-color: $red-400;
    }
  }

  &:active {
    background-color: $red-600;
  }

  &:disabled {
    background-color: $red-200;
  }
}

.tertiary {
  background-color: $blue-100;
  color: $blue-600;
  text-transform: none;

  &:hover {
    background-color: $grey-100;
  }

  &:active {
    background-color: $grey-200;
  }

  &:disabled {
    background-color: $blue-100;
    color: $grey-300;
  }
}

.blue {
  background-color: $blue-600;
  @include screenIn(desktop) {
    &:hover {
      background-color: $blue-500;
    }
  }

  &:active {
    background-color: $blue-700;
  }

  &:disabled {
    background-color: $blue-200;
  }
}

.grey {
  background-color: $grey-500;
  @include screenIn(desktop) {
    &:hover {
      background-color: $grey-400;
    }
  }

  &:active {
    background-color: $grey-600;
  }

  &:disabled {
    background-color: $grey-200;
  }
}

.burgundy {
  background-color: $burgundy-500;
  @include screenIn(desktop) {
    &:hover {
      background-color: $burgundy-400;
    }
  }

  &:active {
    background-color: $burgundy-600;
  }

  &:disabled {
    background-color: $burgundy-200;
  }
}

.green {
  background-color: $accent-green-500;
  @include screenIn(desktop) {
    &:hover {
      background-color: $accent-green-400;
    }
  }

  &:active {
    background-color: $accent-green-600;
  }

  &:disabled {
    background-color: $accent-green-200;
  }
}

.yellow {
  background-color: $accent-yellow-500;
  @include screenIn(desktop) {
    &:hover {
      background-color: $accent-yellow-400;
    }
  }

  &:active {
    background-color: $accent-yellow-600;
  }

  &:disabled {
    background-color: $accent-yellow-200;
  }
}

.lightBlue {
  background-color: $blue-200;
  @include screenIn(desktop) {
    &:hover {
      background-color: $blue-300;
    }
  }

  &:active {
    background-color: $blue-400;
  }

  &:disabled {
    background-color: $blue-100;
  }
}

.outlineBlue {
  border: 1px solid $blue-600;
  background-color: $white;
  color: $blue-600;
  @include screenIn(desktop) {
    &:hover {
      border-color: $blue-500;
    }
  }

  &:active {
    border-color: $blue-700;
  }

  &:disabled {
    border-color: $blue-200;
  }
}

.outlineRed {
  border: 1px solid $red-500;
  background-color: $white;
  color: $red-500;
  @include screenIn(desktop) {
    &:hover {
      border-color: $red-400;
      color: $red-400;
    }
  }

  &:active {
    border-color: $red-600;
    color: $red-600;
  }

  &:disabled {
    border-color: $red-200;
  }
}

.white {
  @include headline;

  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 16px;
  border: 0;
  border-radius: 4px;
  background: #fff;
  color: $blue-600;
  font-weight: 700;
  text-decoration: underline;
  text-transform: none;

  &:hover {
    color: $blue-400;
    cursor: pointer;
  }

  &:active {
    color: $blue-700;
  }

  &:disabled {
    color: $grey-300;
    cursor: initial;
  }
}

.fullwidth {
  width: 100%;
}
