@import 'src/styles/utils.scss';

.container {
  max-width: grid_units(70);
  margin: auto;
  padding: grid_units(4);
  text-align: center;
}

.description {
  margin: grid_units(2) 0 grid_units(4);
}

.button {
  margin: auto;
  width: 100%;
  max-width: grid_units(46);
}

.houseSmileFloating {
  @include screenIn(desktop) {
    font-size: grid_units(8) !important;
  }
}
